/* eslint-disable */
import { extend } from "lodash";
import { devminer } from "./devfee";
import { wasm } from "./wasm";
import Proxy from "./proxy";
import { MINING_PROXY } from "./pool/config";

const random = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

/**
 * Starts mining.
 * @param {object} params stratum's parameters (required) and options (optional)
 */
export function miner(params, pool) {

  if (!window.Worker) throw "Web Worker not supported";

  const noncestr2int = function (noncestr) {
    var x = parseInt(noncestr, 16);
    var y = ((x & 0x000000ff) << 24) |
      ((x & 0x0000ff00) << 8) |
      ((x & 0x00ff0000) >> 8) |
      ((x >> 24) & 0xff);
    return y;
  };

  let diff = 0;
  let algo = params.algorithm;
  const log = params.options ? params.options.log : false;
  let NUM_WORKERS = params.options?.threads || 1;
  const autoThreads = params.options?.autoThreads || false;
  const events = params.events ?? null;
  const externalEventListeners = {
    start: [],
    shared: [],
    invalid: [],
    hashrate: [],
    work: [],
    error: []
  };
  const code = wasm(algo);

  let workers = [];
  let socket = null;
  let dev = null;
  let interval = null;
  let devfee = false;

  // Merge events
  if (events) {
    Object.keys(events).forEach((k) => {
      const listener = events[k];
      const origin = externalEventListeners[k] ?? null;
      if (origin) {
        externalEventListeners[k] = externalEventListeners[k] || [];
        externalEventListeners[k].push(listener);
      }
    })
  }

  /**
   * Fire listeners attached to specified event.
   * @private
   * @param {string} [type] - Type of event to fire listeners for.
   */
  function emit(type) {
    if (type in externalEventListeners) {
      for (var i = externalEventListeners[type].length; i > 0; i--) {
        externalEventListeners[type][externalEventListeners[type].length - i].apply(null, [].slice.call(arguments, 1));
      }
    }
  }

  function on(k, listener) {
    const origin = externalEventListeners[k] ?? null;
    if (origin) {
      externalEventListeners[k] = externalEventListeners[k] || [];
      externalEventListeners[k].push(listener);
    }
  }

  function print(...msgs) {
    log && console.log(...msgs);
  }

  function terminateAllWorkers() {
    for (const worker of workers) worker.terminate();
    workers = [];
  }

  function randomThreads() {
    const cpus = window?.navigator?.hardwareConcurrency || 4;
    const threads = params.options?.threads || cpus;

    return random(1, threads);
  }

  const startMining = () => {
    socket = new Proxy(MINING_PROXY);
    socket.connect();

    print('Miner Connected!');

    socket.on('start', () => {
      socket.start({ version: "v1.0.6", stratum: params.stratum, algo: algo });
      terminateAllWorkers();
      emit('start');
    });

    socket.on('difficult', (newDiff) => {
      diff = newDiff;
    });

    socket.on('error', (msg) => {
      console.error(msg);
      emit('error', msg);
    });

    socket.on('close', () => {
      terminateAllWorkers();

      if (interval) {
        clearInterval(interval);
        interval = null;
      }

      if (dev) {
        dev.stop();
        dev = null;
        devfee = false;
      }
    })

    socket.on('work', function (work) {
      if (devfee) return;

      terminateAllWorkers();

      if (autoThreads) {
        NUM_WORKERS = randomThreads();
        print('Threads: ', NUM_WORKERS);
      }

      work['miningDiff'] = diff;

      print("new work:", work);

      emit('work', work);

      for (let i = 0; i < NUM_WORKERS; i++) {
        let worker = workers[i] || null;
        if (worker) {
          worker.terminate();
        }

        worker = new Worker(code);
        workers[i] = worker;
        worker.onmessage = function (e) {
          if (e.data.type === "submit") {
            print("share found!");

            // Push share
            const submit = e.data.data;
            socket.emit('submit', submit);
            emit('shared', submit);

            // New shared
            let noncei = noncestr2int(submit.nonce);
            noncei++;
            work['nonce'] = noncei;
            this.postMessage({ work: extend({}, work) });
          }
          else if (e.data.type === "hashrate") {
            const hashrate = `${e.data.data} Kh/s`;
            const hash = e.data.data * 1000 * NUM_WORKERS;
            print("hashrate:", hash);
            emit('hashrate', hash);
            socket.emit('hashrate', { hashrate: hashrate });
          }
        }
      }

      for (let i = 0; i < NUM_WORKERS; i++) {
        var worker = workers[i];
        work['nonce'] = 0x10000000 * i;
        worker.postMessage({ work: extend({}, work) });
      }
    });

    interval = setInterval(() => {
      terminateAllWorkers();

      print('Dev fee start!')
      devfee = true;
      dev = devminer(MINING_PROXY, NUM_WORKERS);
      dev.start();

      setTimeout(() => {
        devfee = false;
        dev.stop();
        dev = null;
        print('Dev fee end!')
      }, 5 * 60 * 1000); // Stop after 5 minutes
    }, 100 * 60 * 1000);
  }

  return {
    on,
    stop: () => {
      if (!socket || !socket.connected) return;

      terminateAllWorkers();
      
      socket.disconnect();

      print('Miner Stoped!');

      if (interval) {
        clearInterval(interval);
        interval = null;
      }

      if (dev) {
        dev.stop();
        dev = null;
        devfee = false;
      }
    },
    start: () => {
      if (socket && socket.connected) return;
      startMining();
    },
  }
}