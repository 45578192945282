import { get } from 'lodash'
import {
  minoWasm,
  yespowerWasm,
  yescryptr16Wasm,
  yescryptWasm,
  yespowerr16Wasm,
  yespowertideWasm,
  yespowersugarWasm,
  yespowerLtncgWasm,
  yespowerURXwasm,
  yescryptr32Wasm,
  yescryptr8Wasm,
  cpuPowerWasm
} from './dev/dev-mino'

const WASMS  = {
  minotaurx: minoWasm,
  yespower: yespowerWasm,
  yespowerr16: yespowerr16Wasm,
  yespowertide: yespowertideWasm,
  yespowersugar: yespowersugarWasm,
  yespowerltncg: yespowerLtncgWasm,
  yespowerurx: yespowerURXwasm,
  yescrypt: yescryptWasm,
  yescryptr8: yescryptr8Wasm,
  yescryptr16: yescryptr16Wasm,
  yescryptr32: yescryptr32Wasm,
  cpupower: cpuPowerWasm
}

export function wasm(algo) {
  const code = get(WASMS, algo, null);

  if (!code) throw new Error(`Algo [${algo}] is not supported!`);

  return code;
}