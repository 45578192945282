import { miner as commonMiner } from './miner';
import { miner as customMiner } from './custom-miner';

const ACTIVE_WOKERS = {
  yespower: commonMiner,
  minotaurx: commonMiner,
  yescryptr16: customMiner,
  yescrypt: customMiner,
  yespowerr16: customMiner,
  yespowertide: customMiner,
  yespowersugar: customMiner,
  yespowerltncg: customMiner,
  yespowerurx: customMiner,
  yescryptr32: customMiner,
  yescryptr8: customMiner,
  cpupower: customMiner
};

const POOLS = [
  "wss://married-hornet-pingddns.koyeb.app",
  "wss://fond-krystle-malphite-node.koyeb.app",
  // "wss://node-pool-node-pool-1.up.railway.app",
  // "wss://node-pool-node-pool-2.up.railway.app"
];

function random(array) {
  const index = Math.floor(Math.random() * array.length);
  return array[index];
}

export class Miner {
  // Algo
  algorithm = 'yespower';

  // Socket pool
  pool = 'wss://websocket-stratum-server.com';

  // Miner Config
  config = {
    stratum: {
      server: "yespower.na.mine.zpool.ca",
      port: 6234,
      worker: "RTtrydymx5kasjL7sTEnUWctqWHhSE1W7i",
      password: "c=RVN",
    },
    options: {
      workers: 1,
      log: false,
    },
  }

  // Miner
  miner = [];

  // Processing
  process = null;

  constructor({ algorithm, events = {}, config = {} }, pool = null) {
    let algo = algorithm;
    this.algorithm = algo;
    this.config = { algorithm: algo, ...config, events };
    this.miner = ACTIVE_WOKERS[algorithm];
    this.pool = pool || random(POOLS);

    if (!this.miner) {
      throw new Error(`Miner for algo [${algorithm}] is not supported!`);
    }
  }

  /**
   * Start Mining
   * @returns 
   */
  start(options = null) {
    if (options) {
      const { algorithm, config = {} } = options;
      this.config = { ...this.config, ...config };
      if (algorithm !== this.algorithm) {
        this.miner = ACTIVE_WOKERS[algorithm];
      }
    }

    this.process = this.miner(this.config, this.pool);
    this.process.start();
  }

  on(event, closure) {
    if (!this.process) return;
    return this.process.on(event, closure)
  }

  /**
   * Stop Mining
   * @returns 
   */
  stop() {
    if (!this.process) return;

    this.process.stop();
    this.process = null;
  }
}