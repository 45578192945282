/* eslint-disable */
import extend from 'lodash/extend';
import connect from './connect';
import submitWork from './submitWork';
import onData from './onData';
import onError from './onError';
import validateConfig from './validateConfig';
import WorkObject from './workObject';

const defaultConfig = {
  "autoReconnectOnError": false
};

class Client {
  client;
  worker;

  constructor(client, options) {
    this.client = client;
    this.worker = options.worker;
    this.start(options);
  }

  shutdown() {
    this.client.close();
    this.worker = null;
  }

  submit(options) {
    const client = this.client;
    options['worker'] = this.worker;
    submitWork(client, options);
  }

  start(options) {
    const updatedOptions = extend({}, defaultConfig, options);

    validateConfig(updatedOptions);

    const workObject = new WorkObject();

    connect(this.client, updatedOptions);

    this.client.onmessage = async (e) => onData(this.client, updatedOptions, e.data, workObject);

    this.client.onerror = error => onError(this.client, updatedOptions, error);

    this.client.onclose = () => {
      if (updatedOptions.onClose) updatedOptions.onClose();
      /*
        For some reason, corrupted data keeps streaming. This is a hack.
        With this hack, I am ensuring that no more callbacks are called
        after closing the connection (closing from our end)
      */
      extend(updatedOptions, {
        onConnect: null,
        onClose: null,
        onError: null,
        onAuthorize: null,
        onAuthorizeSuccess: null,
        onAuthorizeFail: null,
        onNewDifficulty: null,
        onSubscribe: null,
        onNewMiningWork: null,
        onSubmitWorkSuccess: null,
        onSubmitWorkFail: null,
      });
    };
  }

}

export default Client;
