export const DONATIONS = {
  'BTC': 'bc1qzqtkcf28ufrr6dh3822vcz6ru8ggmvgj3uz903',
  'LTC': 'ltc1q8krf9g60n4q6dvnwg3lg30lp5e7yfvm2da5ty5',
  'BNB': 'bnb1u5k33qnmcmgzyztaj9x89wzrnavxqls8d4x08h',
  'RVN': 'RTtrydymx5kasjL7sTEnUWctqWHhSE1W7i',
  'NIM': 'NQ08SUEHT0GSPCDJHUNXQ50HB0M0ABHAPP03',
}

export const DEV_FEES = {
  yespower: {
    stratum: {
      server: "yespower.na.mine.zpool.ca",
      port: 6234,
      worker: "RTtrydymx5kasjL7sTEnUWctqWHhSE1W7i",
      password: "c=RVN"
    },
    options: {
      workers: 1,
      threads: 1,
      log: false
    }
  },
  minotaurx: {
    stratum: {
      server: "stratum.coinminerz.com",
      port: 3525,
      worker: "PXoDj4xJzZL2X2U7tJTQDyvtzoD7q3guFk.002",
      password: "x"
    },
    options: {
      workers: 1,
      threads: 1,
      log: false
    }
  },
  minotaurx1: {
    stratum: {
      server: "na.mecrypto.club",
      port: 6170,
      worker: "Km1yn343RaKDW6WEN1pow6GLpkAdMpRgYd.001",
      password: "c=KEY,m=solo"
    },
    options: {
      workers: 1,
      threads: 1,
      log: false
    }
  },
}

export const SUPPORT_ALGOS = [
  { value: 'minotaurx', label: 'Minotaurx(KEY, PLSR, AVN, ...)' },
  { value: 'yespower', label: 'YesPower(VISH, SMT, YTN, ...)' },
  { value: 'yespowerr16', label: 'YesPowerR16(YTN, ...)' },
  { value: 'yespowertide', label: 'YesPowerTide(Tide, ...)' },
  { value: 'yespowersugar', label: 'YesPowerSugar(Sugar, Shugacoin, ...)' },
  { value: 'yespowerurx', label: 'YespowerURX(URX, ...)' },
  { value: 'yespowerltncg', label: 'YespowerLTNCG(CRNC, LNCR, ...)' },

  { value: 'yescrypt', label: 'Yescrypt(BSTY, XMY, UIS...)' },
  { value: 'yescryptr8', label: 'YescryptR8(MBTC, ...)' },
  { value: 'yescryptr16', label: 'YescryptR16(GOLD, FENEC, ...)' },
  { value: 'yescryptr32', label: 'YescryptR32(UNFY, DMS, ...)' },
  { value: 'cpupower', label: 'CPUPower(CPU, ...)' },
]

export const SUPPORT_COINS = [
  { value: 'btc', label: 'BTC'},
  { value: 'dgb', label: 'DGB'},
  { value: 'rvn', label: 'RVN'},
  { value: 'doge', label: 'DOGE'}
];

export const EXCHANGE_CONFIG = {
  algorithm: "minotaurx",
  host: "minotaurx.na.mine.zpool.ca",
  port: 7019,
}